import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// function isMobile() {
//   const flag = navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
//   )

//   return flag
// }
const routes = [
  {
    path: '/',

    // redirect: to => {
    //   const userData = JSON.parse(localStorage.getItem('userData'))
    //   const userRole = userData && userData.roles[0] ? userData.roles[0] : null
    //   if (!userRole) {
    //     return { name: 'auth-login', query: to.query }
    //   }
    //   if (userRole === 'normal' || userRole === 'Staff') {
    //     return { name: 'mobile-dashboard' }
    //   }
    redirect: () => {
      // if (isMobile()) {
      //   return { name: 'mobile-dashboard' }
      // }
      console.log('redirect')

      return {
        name: 'dashboard-list',
      }
    },
    meta: {
      title: 'Homepage',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // {
  //   path: '/pages/register',
  //   name: 'pages-register',
  //   component: () => import('@/views/pages/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error404.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },

  // {
  //   path: '/monthlytransferred-list/:month',
  //   name: 'monthlytransferred-list',
  //   component: () => import('@/views/stock/MonthlyTransferred/MonthlyTransferredList.vue'),
  // },
  // {
  //   path: '/perettogetsujiinfo/:id',
  //   name: 'perettogetsujiinfo',
  //   component: () => import('@/views/stock/MonthlyStocked/PerettoGetsujiInfo.vue'),
  // },
  // {
  //   path: '/dailystocked-list/:id/:month',
  //   name: 'dailystocked-list',
  //   component: () => import('@/views/stock/DailyStocked/DailyStockedList.vue'),
  // },
  // {
  //   path: '/proudctdailydetails/:id',
  //   name: 'proudctdailydetails',
  //   component: () => import('@/views/stock/DailyStocked/ProductDailyStocked.vue'),
  // },
  // {
  //   path: '/materialdailydetails/:id',
  //   name: 'materialdailydetails',
  //   component: () => import('@/views/stock/DailyStocked/MaterialDailyStocked.vue'),
  // },
  // {
  //   path: '/crusheddailydetails/:id',
  //   name: 'crusheddailydetails',
  //   component: () => import('@/views/stock/DailyStocked/CrushedDailyStocked.vue'),
  // },
  // {
  //   path: '/monthlystocked-edit/:month/:id',
  //   name: 'monthlystocked-edit',
  //   component: () => import('@/views/stock/MonthlyStocked/MonthlyStockedEdit_old.vue'),
  // },
  // {
  //   path: '/monthlystocked-list/:month',
  //   name: 'monthlystocked-list',
  //   component: () => import('@/views/stock/MonthlyStocked/MonthlyStockedList.vue'),
  // },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: () => import('@/views/dashboard/Dashboard.vue'),
  // },
  // {
  //   path: '/dashboard-list',
  //   name: 'dashboard-list',
  //   component: () => import('@/views/bimi/dashboard/DashboardList/DashboardList.vue'),
  //   meta: {
  //     title: 'Dashboard',
  //   },
  // },

  // Bimi Stock Dashboard
  {
    path: '/dashboard-list',
    name: 'dashboard-list',
    component: () => import('@/views/bimi/dashboard/DashboardList/DashboardList.vue'),
    meta: {
      title: 'Dashboard',
    },
  },

  // Bimi Stock Products
  // Products
  {
    path: '/items-list',
    name: 'items-list',
    component: () => import('@/views/bimi/product/item/ItemsList.vue'),
    meta: {
      title: 'ItemsList',
    },
  },
  {
    path: '/items-create',
    name: 'items-create',
    component: () => import('@/views/bimi/product/item/ItemsCreate.vue'),
    meta: {
      title: 'ItemsCreate',
    },
  },

  // {
  //   path: '/products-create2',
  //   name: 'products-create2',
  //   component: () => import('@/views/bimi/product/Products/ProductsCreate/ProductsCreate2.vue'),
  //   meta: {
  //     title: 'ProductsCreate2',
  //   },
  // },
  // {
  //   path: '/products-create',
  //   name: 'products-create',
  //   component: () => import('@/views/bimi/product/Products/ProductsCreate/ProductsCreate.vue'),
  //   meta: {
  //     title: 'ProductsCreate',
  //   },
  // },
  {
    path: '/items-edit/:id',
    name: 'items-edit',
    component: () => import('@/views/bimi/product/item/ItemsEdit.vue'),
    meta: {
      title: 'ItemsEdit',
    },
  },

  // ProductSet
  {
    path: '/itemset-list',
    name: 'itemset-list',
    component: () => import('@/views/bimi/product/ItemSet/ItemSetList.vue'),
    meta: {
      title: 'ItemSetList',
    },
  },
  {
    path: '/itemset-create',
    name: 'itemset-create',
    component: () => import('@/views/bimi/product/ItemSet/ItemSetCreate.vue'),
    meta: {
      title: 'ItemSetCreate',
    },
  },
  {
    path: '/itemset-edit/:id',
    name: 'itemset-edit',
    component: () => import('@/views/bimi/product/ItemSet/ItemSetEdit.vue'),
    meta: {
      title: 'ItemSetEdit',
    },
  },

  // ProductRegular
  {
    path: '/subscription-list',
    name: 'subscription-list',
    component: () => import('@/views/bimi/product/Subscription/SubscriptionList.vue'),
    meta: {
      title: 'Subscription',
    },
  },

  {
    path: '/subscription-create',
    name: 'subscription-create',
    component: () => import('@/views/bimi/product/Subscription/SubscriptionCreate.vue'),
    meta: {
      title: 'SubscriptionCreate',
    },
  },

  {
    path: '/subscription-edit/:id',
    name: 'subscription-edit',
    component: () => import('@/views/bimi/product/Subscription/SubscriptionEdit.vue'),
    meta: {
      title: 'SubscriptionEdit',
    },
  },

  // {
  //   path: '/productsregular-div',
  //   name: 'productsregular-div',
  //   component: () => import('@/views/bimi/product/ProductRegular/ProductsRegularCreate/ProductsRegularDiv.vue'),
  //   meta: {
  //     title: 'ProductsCreate',
  //   },
  // },
  // {
  //   path: '/productsregular-nine',
  //   name: 'productsregular-nine',
  //   component: () => import('@/views/bimi/product/ProductRegular/ProductsRegularCreate/ProductsRegularNine.vue'),
  //   meta: {
  //     title: 'ProductsRegularNine',
  //   },
  // },

  // Bimi Stock order
  // OrderList
  {
    path: '/order-history/:id',
    name: 'order-history',
    component: () => import('@/views/bimi/order/OrderHistory.vue'),
    meta: {
      title: 'OrderHistory',
    },
  },
  {
    path: '/order-by-member-list',
    name: 'order-by-member-list',
    component: () => import('@/views/bimi/order/OrderByMemberList/OrderByMemberList.vue'),
    meta: {
      title: 'OrderByMemberList',
    },
  },
  {
    path: '/order-create',
    name: 'order-create',
    component: () => import('@/views/bimi/order/OrderByMemberList/OrderCreate.vue'),
    meta: {
      title: 'OrderCreate',
    },
  },
  {
    path: '/order-edit/:id',
    name: 'order-edit',
    component: () => import('@/views/bimi/order/OrderByMemberList/OrderEdit.vue'),
    meta: {
      title: 'OrderEdit',
    },
  },
  {
    path: '/order-item-list',
    name: 'order-item-list',
    component: () => import('@/views/bimi/order/OrderItemList/OrderItemList.vue'),
    meta: {
      title: 'OrderItemList',
    },
  },
  {
    path: '/order-set-list',
    name: 'order-set-list',
    component: () => import('@/views/bimi/order/OrderSetList/OrderSetList.vue'),
    meta: {
      title: 'OrderSetList',
    },
  },
  {
    path: '/order-subs-list',
    name: 'order-subs-list',
    component: () => import('@/views/bimi/order/OrderSubsList/OrderSubsList.vue'),
    meta: {
      title: 'OrderSubsList',
    },
  },

  // OrderCancel
  {
    path: '/ordercancel-list',
    name: 'ordercancel-list',
    component: () => import('@/views/bimi/order/OrderCancel/OrderCancelList.vue'),
    meta: {
      title: 'OrderCancelList',
    },
  },

  // OrderReturn
  {
    path: '/orderreturn-list',
    name: 'orderreturn-list',
    component: () => import('@/views/bimi/order/OrderReturn/OrderReturnList.vue'),
    meta: {
      title: 'OrderReturnList',
    },
  },

  // Bimi Stock payment
  // Transactions
  {
    path: '/transactions-list',
    name: 'transactions-list',
    component: () => import('@/views/bimi/payment/Transactions/TransactionsList.vue'),
    meta: {
      title: 'TransactionsList',
    },
  },

  // PaymentMethods
  {
    path: '/paymethods-list',
    name: 'paymethods-list',
    component: () => import('@/views/bimi/payment/PaymentMethods/PayMethodsList.vue'),
    meta: {
      title: 'PayMethodsLit',
    },
  },

  // Bimi Stock shipping
  {
    path: '/shipping-list',
    name: 'shipping-list',
    component: () => import('@/views/bimi/shipping/Shipping/ShippingList.vue'),
    meta: {
      title: 'ShippingList',
      showInbreadcrumb: true,
    },
  },
  {
    path: '/shipping-edit/:id',
    name: 'shipping-edit',
    component: () => import('@/views/bimi/shipping/Shipping/ShippingEdit.vue'),
    meta: {
      title: 'ShippingEdit',
      showInbreadcrumb: true,
    },
  },
  {
    path: '/shippingestimated-list',
    name: 'shippingestimated-list',
    component: () => import('@/views/bimi/shipping/ShippingEstimated/ShippingEstimatedList.vue'),
    meta: {
      title: 'ShippingEstimatedList',
      showInbreadcrumb: true,
    },
  },
  {
    path: '/shippinginvoice-list',
    name: 'shippinginvoice-list',
    component: () => import('@/views/bimi/shipping/Invoice/ShippingInvoiceList.vue'),
    meta: {
      title: 'ShippingInvoiceList',
      showInbreadcrumb: true,
    },
  },
  {
    path: '/shippinginvoice-create',
    name: 'shippinginvoice-create',
    component: () => import('@/views/bimi/shipping/Invoice/ShippingInvoiceCreate.vue'),
    meta: {
      title: 'ShippingInvoice-Create',
    },
  },
  {
    path: '/shippinginvoice-edit/:id',
    name: 'shippinginvoice-edit',
    component: () => import('@/views/bimi/shipping/Invoice/ShippingInvoiceEdit.vue'),
    meta: {
      title: 'ShippingInvoice-Edit',
    },
  },

  // Bimi Stock Member
  // MemberList
  {
    path: '/member-list',
    name: 'member-list',
    component: () => import('@/views/bimi/member/MemberList/MemberList.vue'),
    meta: {
      title: 'MemberList',
      showInbreadcrumb: true,
    },
  },
  {
    path: '/member-create',
    name: 'member-create',
    component: () => import('@/views/bimi/member/MemberList/MemberCreate.vue'),
    meta: {
      title: 'MemberCreate',
      showInbreadcrumb: true,
    },
  },
  {
    path: '/member-edit/:id',
    name: 'member-edit',
    component: () => import('@/views/bimi/member/MemberList/MemberEdit.vue'),
    meta: {
      title: 'MemberEdit',
      showInbreadcrumb: true,
    },
  },

  // 定期便会員
  {
    path: '/member-subs-list',
    name: 'member-subs-list',
    component: () => import('@/views/bimi/member/subscription/MemberSubsList.vue'),
    meta: {
      title: 'MemberVipList',
      showInbreadcrumb: true,
    },
  },
  { // 定期便注文
    path: '/scheduledsubscription-list',
    name: 'scheduledsubscription-list',
    component: () => import('@/views/bimi/scheduledsubscription/ScheduledSubscriptionList.vue'),
    meta: {
      title: 'ScheduledSubscriptionList',
    },
  },
  { // 定期便注文
    path: '/scheduledsubscription-create',
    name: 'scheduledsubscription-create',
    component: () => import('@/views/bimi/scheduledsubscription/ScheduledSubscriptionCreate.vue'),
    meta: {
      title: 'ScheduledSubscriptionCreate',
    },
  },
  { // 定期便注文
    path: '/scheduledsubscription-edit/:id',
    name: 'scheduledsubscription-edit',
    component: () => import('@/views/bimi/scheduledsubscription/ScheduledSubscriptionEdit.vue'),
    meta: {
      title: 'ScheduledSubscriptionEdit',
    },
  },
  {
    path: '/subscription-order-history/:id',
    name: 'subscription-order-history',
    component: () => import('@/views/bimi/scheduledsubscription/SubscriptionOrderHistory.vue'),
    meta: {
      title: 'SubscriptionOrderHistory',
    },
  },

  // Bimi Stock Stock
  { // 現在在庫
    path: '/stock-current',
    name: 'stock-current',
    component: () => import('@/views/bimi/stock/stockCurrent/StockCurrent.vue'),
    meta: {
      title: 'StockCurrent',
    },
  },

  { // 現在在庫単品入出庫詳細
    path: '/item-daily-stock/:month',
    name: 'item-daily-stock',
    component: () => import('@/views/bimi/stock/stockCurrent/ItemDailyStock.vue'),
  },
  { // 月末繰越
    path: '/stock-prevmon/:month',
    name: 'stock-prevmon',
    component: () => import('@/views/bimi/stock/carryover/CarryoverList.vue'),
    meta: {
      title: 'StockCarryover',
    },
  },
  { // 月次入出庫
    path: '/stock-monthly/:month',
    name: 'stock-monthly',
    component: () => import('@/views/bimi/stock/StockMonthly/StockMonthly.vue'),
    meta: {
      title: 'StockMonthly',
    },
  },
  { // 月次入出庫詳細
    path: '/stock-item-daily/:month',
    name: 'stock-item-daily',
    component: () => import('@/views/bimi/stock/StockDaily/StockItemDaily.vue'),
  },

  { // 日毎入出庫詳細―セット
    path: '/stock-set-daily/:month',
    name: 'stock-set-daily',
    component: () => import('@/views/bimi/stock/StockDaily/StockSetDaily.vue'),
  },

  { // 日毎入出庫詳細―セット
    path: '/stock-subs-daily/:month',
    name: 'stock-subs-daily',
    component: () => import('@/views/bimi/stock/StockDaily/StockSubsDaily.vue'),
  },

  // 問い合わせ
  {
    path: '/faq-list',
    name: 'faq-list',
    component: () => import('@/views/bimi/faq/FaqList.vue'),
    meta: {
      title: 'FaqList',
      showInbreadcrumb: true,
    },
  },
  {
    path: '/faq-create',
    name: 'faq-create',
    component: () => import('@/views/bimi/faq/FaqCreate.vue'),
    meta: {
      title: 'FaqCreate',
      showInbreadcrumb: true,
    },
  },
  {
    path: '/faq-edit/:id',
    name: 'faq-edit',
    component: () => import('@/views/bimi/faq/FaqEdit.vue'),
    meta: {
      title: 'FaqEdit',
      showInbreadcrumb: true,
    },
  },

  // {
  //   path: '/stockset-list',
  //   name: 'stockset-list',
  //   component: () => import('@/views/bimi/stockmag/StockSet/StockSetList.vue'),
  //   meta: {
  //     title: 'StockSetList',
  //   },
  // },
  // {
  //   path: '/stocksregular-list',
  //   name: 'stocksregular-list',
  //   component: () => import('@/views/bimi/stockmag/StockRegular/StocksRegularList.vue'),
  //   meta: {
  //     title: 'StockRegularList',
  //   },
  // },

  // Bimi Stock setting
  // Media
  {
    path: '/media-list',
    name: 'media-list',
    component: () => import('@/views/bimi/setting/Media/MediaList.vue'),
    meta: {
      title: 'MediaList',
    },
  },

  // Employee
  {
    path: '/employee-list',
    name: 'employee-list',
    component: () => import('@/views/bimi/setting/Employee/EmployeeList.vue'),
    meta: {
      title: 'EmployeeList',
    },
  },
  {
    path: '/employee-create',
    name: 'employee-create',
    component: () => import('@/views/bimi/setting/Employee/EmployeeCreate.vue'),
    meta: {
      title: 'EmployeeCreate',
    },
  },
  {
    path: '/employee-edit/:id',
    name: 'employee-edit',
    component: () => import('@/views/bimi/setting/Employee/EmployeeEdit.vue'),
    meta: {
      title: 'EmployeeEdit',
    },
  },

  {
    path: '/purchase-list',
    name: 'purchase-list',
    component: () => import('@/views/bimi/purchase/PurchaseList/PurchaseList.vue'),
    meta: {
      title: 'PurchaseList',
    },
  },
  {
    path: '/purchase-create',
    name: 'purchase-create',
    component: () => import('@/views/bimi/purchase/PurchaseList/PurchaseCreate.vue'),
    meta: {
      title: 'PurchaseCreate',
    },
  },
  {
    path: '/purchase-edit/:id',
    name: 'purchase-edit',
    component: () => import('@/views/bimi/purchase/PurchaseList/PurchaseEdit.vue'),
    meta: {
      title: 'PurchaseEdit',
    },
  },

  {
    path: '/purchase-pay',
    name: 'purchase-pay',
    component: () => import('@/views/bimi/purchase/PurchasePay/PurchasePay.vue'),
    meta: {
      title: 'PurchasePay',
    },
  },
  {
    path: '/purchase-pay-create',
    name: 'purchase-pay-create',
    component: () => import('@/views/bimi/purchase/PurchasePay/PurchasePayCreate.vue'),
    meta: {
      title: 'PurchasePayCreate',
    },
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('@/views/bimi/purchase/Category/Category.vue'),
    meta: {
      title: 'Category',
    },
  },
  {
    path: '/product-create',
    name: 'product-create',
    component: () => import('@/views/bimi/purchase/ProductCreate/ProductCreate.vue'),
    meta: {
      title: 'ProductCreate',
    },
  },

  // Supplier
  {
    path: '/supplier-list',
    name: 'supplier-list',
    component: () => import('@/views/bimi/setting/Supplier/SupplierList/SupplierList.vue'),
    meta: {
      title: 'SupplierList',
    },
  },
  {
    path: '/supplier-edit/:id',
    name: 'supplier-edit',
    component: () => import('@/views/bimi/setting/Supplier/SupplierList/SupplierEdit.vue'),
    meta: {
      title: 'SupplierEdit',
    },
  },
  {
    path: '/supplier-create',
    name: 'supplier-create',
    component: () => import('@/views/bimi/setting/Supplier/SupplierList/SupplierCreate.vue'),
    meta: {
      title: 'SupplierCreate',
    },
  },
  {
    path: '/supplier-delete',
    name: 'supplier-delete',
    component: () => import('@/views/bimi/setting/Supplier/SupplierList/SupplierDelete.vue'),
    meta: {
      title: 'SupplierDelete',
    },
  },

  // user
  {
    path: '/user-list',
    name: 'user-list',
    component: () => import('@/views/bimi/setting/User/UserList.vue'),
    meta: {
      title: 'UserList',
    },
  },
  {
    path: '/user-create',
    name: 'user-create',
    component: () => import('@/views/bimi/setting/User/UserCreate.vue'),
    meta: {
      title: 'UserCreate',
    },
  },
  {
    path: '/user-edit',
    name: 'user-edit',
    component: () => import('@/views/bimi/setting/User/UserEdit.vue'),
    meta: {
      title: 'UserEdit',
    },
  },
  {
    path: '/user-edit-pwd/:id',
    name: 'user-edit-pwd',
    component: () => import('@/views/bimi/setting/User/UserEditPwd.vue'),
    meta: {
      title: 'UserEditPwd',
    },
  },

  // role
  {
    path: '/role-list',
    name: 'role-list',
    component: () => import('@/views/bimi/setting/Role/RoleList.vue'),
  },
  {
    path: '/role-create',
    name: 'role-create',
    component: () => import('@/views/bimi/setting/Role/RoleCreate.vue'),
  },
  {
    path: '/role-edit/:id',
    name: 'role-edit',
    component: () => import('@/views/bimi/setting/Role/RoleEdit.vue'),
  },

  // master
  {
    path: '/master-list',
    name: 'master-list',
    component: () => import('@/views/bimi/setting/Master/MasterList.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Resolve route duplication
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
