import Vue from 'vue'
import Vuex from 'vuex'

import largeclassStore from './modules/largeclassStore'
import smallclassStore from './modules/smallclassStore'
import mediumclassStore from './modules/mediumclassStore'
import appConfigStoreModule from '@/core/app-config/appConfigStoreModule'

import supplierStore from './modules/supplierStore'
import setStore from './modules/setStore'
import itemStore from './modules/itemStore'
import subscriptionStore from './modules/subscriptionStore'
import purchasedStore from './modules/purchasedStore'
import owncodeStore from './modules/owncodeStore'
import nfcontentStore from './modules/nfcontentStore'
import memberStore from './modules/memberStore'
import categoryStore from './modules/categoryStore'
import faqStore from './modules/faqStore'
import orderStore from './modules/orderStore'
import productStore from './modules/productStore'
import shippingStore from './modules/shippingStore'

import mediaStore from './modules/mediaStore'
import monthlyCarryoverStore from './modules/monthlyCarryoverStore'
import currentlyStockStore from './modules/currentlyStockStore'
import monthlyStockStore from './modules/monthlyStockStore'
import dailyStockStore from './modules/dailyStockStore'
import settlementStore from './modules/settlementStore'
import prefectureStore from './modules/prefectureStore'
import scheduledSubsStore from './modules/scheduledSubsStore'
import paymentHistoryStore from './modules/paymentHistoryStore'
import subscribedMemberStore from './modules/subscribedMemberStore'
import couponStore from './modules/couponStore'
import dashboardStore from './modules/dashboardStore'
import userStore from './modules/userStore'
import roleStore from './modules/roleStore'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    supplierStore,

    largeclassStore,
    mediumclassStore,
    smallclassStore,

    appConfig: appConfigStoreModule,

    app,
    setStore,
    itemStore,
    subscriptionStore,
    memberStore,
    categoryStore,
    faqStore,
    productStore,
    shippingStore,

    mediaStore,
    nfcontentStore,
    owncodeStore,
    purchasedStore,
    monthlyCarryoverStore,
    monthlyStockStore,
    currentlyStockStore,
    orderStore,
    dailyStockStore,
    settlementStore,
    prefectureStore,
    scheduledSubsStore,
    paymentHistoryStore,
    subscribedMemberStore,
    couponStore,
    dashboardStore,
    userStore,
    roleStore,
  },
})
