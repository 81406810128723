import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueQuillEditor from 'vue-quill-editor'
import Vuelidate from 'vuelidate'
import draggable from 'vuedraggable'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import {
  init,
} from '@/api/agent'

import './plugins/acl'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import formatDateTime from '@/utils/format'
import {
  deepCopy,
  isObjectEqual,
} from './utils/clone'

init(store, router)
Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(CKEditor)

Vue.use(VueQuillEditor)

Vue.component('draggable', draggable)

// Vue.component('VueQuillEditor', VueQuillEditor)

Vue.prototype.$formatDt = formatDateTime
Vue.prototype.$deepCopy = deepCopy
Vue.prototype.$isObjectEqual = isObjectEqual

Vue.prototype.$getUniqueId = (len, prefix) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const UniqueId = []
  /* eslint no-bitwise: ["error", { "allow": ["|"] }] */
  for (let i = 0; i < len; i += 1) UniqueId[i] = chars[Math.random() * chars.length | 0]

  return `${prefix}-${UniqueId.join('')}`
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
