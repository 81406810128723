import agent from '../../api/agent'

const mediumclass = {
  namespaced: true,
  state: {
    mediumclassList: [],
    mediumclass: undefined,
    selectedMediumClass: undefined,
  },
  mutations: {
    updateMediumClassList(state, value) {
      state.mediumclassList = value
    },
    updateSelectedMediumClass(state, value) {
      state.selectedMediumClass = value
    },
  },
  actions: {
    async loadMediumClassList(context) {
      const result = await agent.MediumClasses.all()
      context.commit('updateMediumClassList', result.data)
    },
    async loadMediumClass(context, id) {
      try {
        const result = await agent.MediumClasses.detail(id)
        context.commit('updateSelectedMediumClass', result.data)
      } catch (error) {
        console.log('loadMediumClass,error:', error)
      }
    },
    async createMediumClass(context, mediumclasses) {
      await agent.MediumClasses.add(mediumclasses)
    },
    async editMediumClass(context, mediumclasses) {
      const obj = { ...mediumclasses }
      await agent.MediumClasses.update(obj)
    },
  },
}

export default mediumclass
