import agent from '../../api/agent'

const supplier = {
  namespaced: true,
  state: {
    supplierList: [],
    selectedSupplier: {
    },
  },
  mutations: {
    updateSupplierList(state, value) {
      state.supplierList = value
    },
    clearSupplierList(state) {
      state.supplierList = []
    },
    updateSelectedSupplier(state, value) {
      state.selectedSupplier = value
    },
    clearSelectedSupplier(state) {
      state.selectedSupplier = {
      }
    },
  },
  actions: {
    async loadSupplierList(context, query) {
      const result = await agent.Suppliers.all(query)
      context.commit('updateSupplierList', result.data)
    },

    async createSupplier(context, suppliers) {
      await agent.Suppliers.add(suppliers)
    },

    async loadSupplier(context, id) {
      const result = await agent.Suppliers.detail(id)
      context.commit('updateSelectedSupplier', result.data)
    },

    async editSupplier(context, suppliers) {
      const obj = {
        ...suppliers,
      }
      await agent.Suppliers.update(obj)
    },

    async deleteSupplier(context, id) {
      await agent.Suppliers.delete(id)
    },
  },
}

export default supplier
