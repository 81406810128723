import agent from '../../api/agent'

const counpons = {
  namespaced: true,
  state: {
    couponList: [],
    selectedCoupon: {
    },
  },
  mutations: {
    updateCouponList(state, newValue) {
      state.couponList = newValue
    },
    clearCouponList(state) {
      state.couponList = []
    },
    updateSelectedCoupon(state, newValue) {
      state.selectedCoupon = newValue
    },
  },
  actions: {
    async loadCouponList(context, query) {
      context.commit('clearCouponList')
      const result = await agent.Coupons.all(query)
      context.commit('updateCouponList', result.data)

      // console.log('loadCouponList', result.data)
    },

    async createCoupons(context, coupon) {
      console.log('coupon', coupon)
      await agent.Coupons.add(coupon)
    },

    async loadCoupon(context, id) {
      try {
        const result = await agent.Coupons.detail(id)
        context.commit('updateSelectedCoupon', result.data)
      } catch (error) {
        console.log('error', error)
      }
    },

    async editCoupons(context, coupon) {
      await agent.Coupons.update(coupon)
    },

    async deleteCoupons(context, id) {
      await agent.Coupons.delete(id)
    },
  },
}

export default counpons
