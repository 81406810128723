/* eslint-disable no-unused-vars */
import {
  mdiFileOutline,
  mdiCogOutline,
  mdiBriefcase,
  mdiAccountGroup,
  mdiCube,
  mdiViewList,
  mdiMessageTextClockOutline,
  mdiFileDocumentEdit,
  mdiFileClockOutline,
  mdiClipboardListOutline,
  mdiClipboardArrowUpOutline,
  mdiEarth,
  mdiMapMarkerMultiple,
  mdiDrawingBox,
  mdiClipboardCheckMultipleOutline,
  mdiClipboardFlowOutline,
  mdiAccount,
  mdiCart,
  mdiBadgeAccount,
  mdiCarLiftedPickup,
  mdiAccountGroupOutline,
  mdiAccountStar,
  mdiAccountStarOutline,
  mdiPackageVariantClosed,
  mdiHome,
  mdiPackageVariant,
  mdiCreditCardOutline,
  mdiCartRemove,
  mdiCartVariant,
  mdiCartArrowDown,
  mdiTable,
  mdiTableMultiple,
  mdiTableClock,
  mdiTextBoxCheckOutline,
  mdiTextBoxEditOutline,
  mdiCartOutline,
  mdiCartArrowRight,
  mdiBookCogOutline,
  mdiScript,
  mdiScriptOutline,
  mdiScriptText,
} from '@mdi/js'

// import others from './others'

export default [
  {
    title: 'Dashboard',

    // to: { name: 'dashboard' },
    icon: mdiHome,

    // resource: '',
    children: [
      {
        title: 'Dashboard',
        to: {
          name: 'dashboard-list',
        },
        icon: mdiCube,
        resource: 'mobileDashboard',
        action: 'create',
      },
    ],
  },

  {
    title: '商品管理',
    icon: mdiClipboardListOutline,
    children: [
      {
        title: '単品',
        to: {
          name: 'items-list',
        },
        icon: mdiTable,
        resource: 'crushingActual',
        action: 'read',
      },
      {
        title: 'セット',
        to: {
          name: 'itemset-list',
        },
        icon: mdiTableMultiple,
        resource: 'blender',
        action: 'read',
      },

      {
        title: '定期便',
        to: {
          name: 'subscription-list',
        },
        icon: mdiTableClock,
        resource: 'production',
        action: 'read',
      },
    ],
  },
  {
    title: '仕入管理',
    icon: mdiClipboardListOutline,
    children: [
      {
        title: '仕入先',
        to: {
          name: 'supplier-list',
        },
        icon: mdiDrawingBox,
        resource: 'supplier',
        action: 'read',
      },

      {
        title: '仕入実績',
        to: {
          name: 'purchase-list',
        },
        icon: mdiTable,
        resource: 'purchase',
        action: 'read',
      },

      // {
      //   title: '商品新規',
      //   to: {
      //     name: 'product-create',
      //   },
      //   icon: mdiTable,
      //   resource: 'product',
      //   action: 'read',
      // },

      // {
      //   title: 'カテゴリー',
      //   to: {
      //     name: 'category',
      //   },
      //   icon: mdiTable,
      //   resource: 'product',
      //   action: 'read',
      // },
      // {
      //   title: '購買支払',
      //   to: {
      //     name: 'purchase-pay',
      //   },
      //   icon: mdiTable,
      //   resource: 'purchase',
      //   action: 'read',
      // },
    ],
  },
  {
    title: '新規注文',
    icon: mdiCart,
    children: [
      {
        title: '注文実績',
        to: {
          name: 'order-by-member-list',
        },
        icon: mdiCartVariant,
        resource: 'firstorder',
        action: 'read',
      },

      {
        title: 'セット実績',
        to: {
          name: 'order-set-list',
        },
        icon: mdiCart,
        resource: 'firstorder',
        action: 'read',
      },
      {
        title: '定期便実績',
        to: {
          name: 'order-subs-list',
        },
        icon: mdiCartArrowRight,
        resource: 'firstorder',
        action: 'read',
      },
      {
        title: 'キャンセル',
        to: {
          name: 'ordercancel-list',
        },
        icon: mdiCartRemove,
        resource: 'firstorder',
        action: 'read',
      },
      {
        title: '返品',
        to: {
          name: 'orderreturn-list',
        },
        icon: mdiCartArrowDown,
        resource: 'firstorder',
        action: 'read',
      },
    ],
  },
  {
    title: '定期便注文',
    icon: mdiCreditCardOutline,
    children: [
      {
        title: '注文スケジュール',
        to: {
          name: 'scheduledsubscription-list',
        },
        icon: mdiViewList,
        resource: 'schduled',
        action: 'read',
      },

    ],
  },
  {
    title: '在庫管理',
    icon: mdiPackageVariantClosed,
    children: [
      {
        title: '現在在庫',
        to: {
          name: 'stock-current',
          query: {
            selTab: 'item',
          },
        },
        icon: mdiTable,
        resource: 'stock',
        action: 'read',
      },
      {
        title: '前月繰越',
        to: {
          name: 'stock-prevmon',
          params: {
            month: new Date(new Date(Date.now()).setDate(0) - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substring(0, 7)
              .replace('-', ''),
          },

        },
        icon: mdiTableMultiple,
        resource: 'stock',
        action: 'read',
      },
      {
        title: '月次入出庫',
        to: {
          name: 'stock-monthly',
          params: {
            month: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substring(0, 7)
              .replace('-', ''),
          },
          query: {
            selTab: 'item',
          },
        },
        icon: mdiTableClock,
        resource: 'stock',
        action: 'read',
      },
    ],
  },
  {
    title: '出荷管理',
    icon: mdiPackageVariant,
    children: [
      {
        title: '出荷予定',
        to: {
          name: 'shippingestimated-list',
        },
        icon: mdiScriptOutline,
        resource: 'contract-crushed',
        action: 'read',
      },

      {
        title: '日伯伝票',
        to: {
          name: 'shippinginvoice-list',
        },
        icon: mdiScriptText,
        resource: 'contract-crushed',
        action: 'read',
      },
      {
        title: '出荷実績',
        to: {
          name: 'shipping-list',
        },
        icon: mdiScript,
        resource: 'contract-crushed',
        action: 'read',
      },
    ],
  },

  {
    title: '決済管理',
    icon: mdiCreditCardOutline,
    children: [
      {
        title: '決済実績',
        to: {
          name: 'transactions-list',
        },
        icon: mdiViewList,
        resource: 'stock',
        action: 'read',
      },

    ],
  },

  {
    title: '会員管理',
    icon: mdiAccountGroupOutline,
    children: [
      {
        title: '会員一覧',
        to: {
          name: 'member-list',
        },
        icon: mdiAccountStar,
        resource: 'member',
        action: 'read',
      },

      {
        title: '定期便会員',
        to: {
          name: 'member-subs-list',
        },
        icon: mdiAccountStarOutline,
        resource: 'member',
        action: 'read',
      },
    ],
  },
  {
    title: '問い合わせ管理',
    icon: mdiAccountGroupOutline,
    children: [
      {
        title: '問い合わせ一覧',
        to: {
          name: 'faq-list',
        },
        icon: mdiAccountStar,
        resource: 'member',
        action: 'read',
      },

    ],
  },
  {
    title: '各種設定',
    icon: mdiCogOutline,
    children: [

      {
        title: '画像',
        to: {
          name: 'media-list',
        },
        icon: mdiDrawingBox,
        resource: 'product',
        action: 'read',
      },
      {
        title: 'カテゴリー',

        // to: { name: 'customers-list' },
        icon: mdiBriefcase,
        resource: 'customer',
        action: 'read',
      },

      // {
      //   title: '社員',
      //   to: { name: 'employee-list' },
      //   icon: mdiAccountGroup,
      //   resource: 'employee',
      //   action: 'read',
      // },
      {
        title: 'ユーザー',
        to: {
          name: 'user-list',
        },
        icon: mdiAccount,
        resource: 'user',
        action: 'read',
      },
      {
        title: '権限',
        to: {
          name: 'role-list',
        },
        icon: mdiBadgeAccount,
        resource: 'role',
        action: 'read',
      },
      {
        title: '各種マスター',
        to: {
          name: 'master-list',
        },
        icon: mdiBookCogOutline,
        resource: 'role',
        action: 'read',
      },
    ],
  },
]

// Array of sections
// export default [...others]
