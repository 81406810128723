import agent from '../../api/agent'

const prefecture = {
  namespaced: true,
  state: {
    prefectureList: [],
    areaListMaster: [],
  },
  mutations: {
    updatePrefectureList(state, newValue) {
      state.prefectureList = newValue
    },
    updateAreaList(state, newValue) {
      state.areaListMaster = []
      newValue.forEach(v => {
        const obj = {
        }
        obj.text = v.sf_grp_name
        obj.value = v.sf_grp_id
        if (!state.areaListMaster.find(alm => alm.text === obj.text)) {
          state.areaListMaster.push(obj)
        }
      })
    },
    clearPrefectureList(state) {
      state.prefectureList = []
    },
  },
  actions: {
    async loadPrefectureList(context, query) {
      context.commit('clearPrefectureList')
      const result = await agent.Prefecture.all(query)

      // console.log('result@:', result)
      context.commit('updatePrefectureList', result.data)
    },
    async loadAreaList(context, query) {
      // console.log('context@:', context)
      const result = await agent.Prefecture.area(query)

      // console.log('result@:', result)
      context.commit('updateAreaList', result.data)
    },
  },
}

export default prefecture
