import agent from '../../api/agent'

// 現在在庫
const currentlyStockStore = {
  namespaced: true,
  state: {
    ItemCurrentlyStock: [],
    f_ItemCurrentlyStock: [],
    SetCurrentlyStock: [],
    SubsCurrentlyStock: [],
  },

  getters: {
  },

  mutations: {
    updateItemCurrentlyStock(state, newValue) {
      state.ItemCurrentlyStock = newValue
    },

    filterItemCurrentlyStock(state, newValue) {
      state.f_ItemCurrentlyStock = state.ItemCurrentlyStock.filter(f => {
        const iFlag = newValue ? f.item_code === newValue : true

        return iFlag
      })
    },

    updateSetCurrentlyStock(state, newValue) {
      state.SetCurrentlyStock = newValue
    },

    updateSubsCurrentlyStock(state, newValue) {
      state.SubsCurrentlyStock = newValue
    },

    clearItemCurrentlyStock(state) {
      state.ItemCurrentlyStock = []
      state.SetCurrentlyStock = []
      state.SubsCurrentlyStock = []
      state.f_ItemCurrentlyStock = []
    },
  },

  actions: {
    async loadItemCurrentlyStock(context, Month) {
      try {
        const result = await agent.CurrentlyStock.all({
          type: 'item', month: Month,
        })

        context.commit('updateItemCurrentlyStock', result.data)
      } catch (error) {
        console.log(error)
      }
    },

    async loadSetCurrentlyStock(context, params) {
      try {
        const result = await agent.CurrentlyStock.all(params)

        context.commit('updateSetCurrentlyStock', result.data)
      } catch (error) {
        console.log(error)
      }
    },

    async loadSubsCurrentlyStock(context, params) {
      try {
        const result = await agent.CurrentlyStock.all(params)

        context.commit('updateSubsCurrentlyStock', result.data)
      } catch (error) {
        console.log(error)
      }
    },

  },
}

export default currentlyStockStore
