import agent from '../../api/agent'

const product = {
  namespaced: true,
  state: {
    ProductList: [],
    ProductCodes: [],
  },
  mutations: {
    updateProductList(state, newValue) {
      state.ProductList = newValue
      state.ProductCodes = newValue.map(v => v.product_code)
    },
    clearProductCodes(state) {
      state.ProductCodes = []
    },
  },
  actions: {
    async loadProductList(context, query) {
      const result = await agent.Products.allProducts(query)
      context.commit('updateProductList', result.data)
    },
  },
}

export default product
