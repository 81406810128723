import agent from '../../api/agent'

const settlement = {
  namespaced: true,
  state: {
    settlementList: [],
    selectedSettlement: {
    },
  },
  mutations: {
    updateSettlementList(state, newValue) {
      state.settlementList = newValue
    },
    updateSettlement(state, newValue) {
      state.selectedSettlement = newValue
    },
  },
  actions: {
    async loadSettlementList(context, query) {
      const result = await agent.Settlement.all(query)
      context.commit('updateSettlementList', result.data)
    },
    async loadSettlement(context, query) {
      const result = await agent.Settlement.detail(query)
      context.commit('updateSettlement', result.data)
    },
  },
}

export default settlement
