import agent from '../../api/agent'

const paymentHistory = {
  namespaced: true,
  state: {
    paymentHistoryList: [],
  },
  mutations: {
    updatePaymentHistory(state, newValue) {
      state.paymentHistoryList = newValue
    },
    clearPaymentHistory(state) {
      state.paymentHistoryList = []
    },
  },
  actions: {
    async loadPaymentHistory(context, query) {
      const result = await agent.PaymentHistory.all(query)
      context.commit('updatePaymentHistory', result.data)
    },
  },
}

export default paymentHistory
