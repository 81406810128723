import agent from '../../api/agent'

const set = {
  namespaced: true,
  state: {
    setList: [],
    selectedSet: {
    },
    setCodes: [],
    setNames: [],
    setItems: [],
  },
  mutations: {
    updateSetList(state, newValue) {
      state.setList = newValue
      newValue.forEach((sl, i) => {
        if (sl.set_images) {
          state.setList[i].set_image = `${sl.imgUrl_prefix}/${sl.set_images.split('"')[1].replace(/^\//g, '')}`
        } else {
          state.setList[i].set_image = ''
        }
      })
    },

    clearSetList(state) {
      state.setList = []
    },

    updateSelectedSet(state, newValue) {
      state.selectedSet = newValue
    },

    updateSetCodes(state, newValue) {
      state.setCodes = newValue.map(v => v.set_code)
      state.setNames = newValue.map(v => v.set_name)
    },

    updateSetItems(state, newValue) {
      state.setItems = newValue
    },
    clearSelectedSet(state) {
      state.selectedSet = {
      }
    },
    clearSetCodes(state) {
      state.setCodes = []
    },
  },
  actions: {
    async loadSetList(context, query) {
      const result = await agent.Sets.all(query)
      context.commit('updateSetList', result.data)
    },
    async createSet(context, items) {
      await agent.Sets.add(items)
    },
    async loadSet(context, id) {
      const result = await agent.Sets.detail(id)
      context.commit('updateSelectedSet', result.data)
    },
    async editSet(context, Set) {
      const obj = {
        ...Set,
      }

      // console.log('editSet:', obj)
      await agent.Sets.update(obj)
    },

    async deleteSet(context, id) {
      await agent.Sets.delete(id)
    },

    async loadSetCodes(context) {
      // console.log('context@:', context)
      const result = await agent.Sets.allcodes()

      // console.log('result@:', result)
      context.commit('updateSetCodes', result.data)
    },

    async loadSetItems(context, query) {
      const result = await agent.Sets.items(query)
      context.commit('updateSetItems', result.data)
    },
  },
}

export default set
