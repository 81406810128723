import agent from '../../api/agent'

const faqs = {
  namespaced: true,
  state: {
    faqList: [],
    selectedFaq: {
    },
  },
  mutations: {
    updateFaqList(state, newValue) {
      state.faqList = newValue
    },
    updateSelectedFaq(state, newValue) {
      state.selectedFaq = newValue
    },
  },
  actions: {
    async loadFaqList(context, query) {
      const result = await agent.Faqs.all(query)
      context.commit('updateFaqList', result.data)

      // console.log('loadFaqList', result.data)
    },
    async createFaq(context, items) {
      await agent.Faqs.add(items)
    },
    async loadFaq(context, id) {
      try {
        const result = await agent.Faqs.detail(id)
        context.commit('updateSelectedFaq', result.data)
      } catch (error) {
        console.log('error', error)
      }
    },
    async editFaq(context, faq) {
      const obj = {
        ...faq,
      }
      await agent.Faqs.update(obj)
    },

    async deleteFaq(context, id) {
      await agent.Faqs.delete(id)
    },
  },
}

export default faqs
