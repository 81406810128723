import agent from '../../api/agent'

const order = {
  namespaced: true,
  state: {
    orderList: [],
    OrdersDetail: [],
    orderByMemberList: [],
    orderSetList: [],
    orderSubsList: [],
    orderProgressList: [],
    orderStatusCode: [],
    orderStatusDesc: [],
    orderStatusMaster: [],
    orderStatusList: [],
    selectedOrder: {
    },
    selectedStatus: {
    },
    CanceledList: [],
    shippingFeeInfo: {
    },
  },
  mutations: {
    updateOrderList(state, newValue) {
      state.orderList = newValue
    },

    updateOrderstatus(state, newValue) {
      state.orderStatusList = newValue
      state.orderStatusMaster = newValue.map(nv => {
        const obj = {
        }
        obj.text = `${nv.os_code}: ${nv.os_desc}`
        obj.value = nv.os_code

        return obj
      })
      state.orderStatusCode = newValue.map(v => v.os_code)

      state.orderStatusDesc = newValue.map(v => v.os_desc)
    },

    clearOrderStatusMaster(state) {
      state.orderStatusList = []
      state.orderStatusMaster = []
    },

    updateOrderMember(state, newValue) {
      state.orderByMemberList = newValue
    },

    clearOrderMember(state) {
      state.orderByMemberList = []
    },

    updateOrderSet(state, newValue) {
      state.orderSetList = newValue
    },

    clearOrderSet(state) {
      state.orderSetList = []
    },

    updateOrderSubs(state, newValue) {
      state.orderSubsList = newValue
    },

    clearOrderSubs(state) {
      state.orderSubsList = []
    },

    updateOrderProgress(state, newValue) {
      state.orderProgressList = newValue
    },

    updateSelectedOrder(state, newValue) {
      state.selectedOrder = newValue
    },

    clearSelectedOrder(state) {
      state.selectedOrder = {
      }
    },

    updateSelectedStatus(state, newValue) {
      state.selectedStatus = newValue
    },

    updateCanceledList(state, newValue) {
      state.CanceledList = newValue
    },

    clearCanceledList(state) {
      state.CanceledList = []
    },

    updateShippingFeeInfo(state, newValue) {
      state.shippingFeeInfo = newValue
    },
    updateOrdersDetail(state, newValue) {
      state.OrdersDetail = newValue
    },
  },
  actions: {
    async loadOrderList(context, query) {
      const result = await agent.Orders.all(query)
      context.commit('updateOrderList', result.data)
    },

    async loadOrdersDetail(context, query) {
      const result = await agent.Orders.exportOrdersDetail(query)
      context.commit('updateOrdersDetail', result.data)
    },

    async createOrder(context, orders) {
      console.log('orders', orders)
      await agent.Orders.add(orders)
    },

    async loadOrder(context, id) {
      try {
        const result = await agent.Orders.detail(id)
        context.commit('updateSelectedOrder', result.data)
      } catch (error) {
        console.log('error', error)
      }
    },

    async loadStatus(context, id) {
      try {
        const result = await agent.Orders.detailStatus(id)
        context.commit('updateSelectedStatus', result.data)
      } catch (error) {
        console.log('error', error)
      }
    },

    async loadOrderStatus(context, query) {
      context.commit('clearOrderStatusMaster')
      const result = await agent.Orders.allStatus(query)
      context.commit('updateOrderstatus', result.data)
    },

    async loadOrderMember(context, query) {
      const result = await agent.Orders.allMember(query)
      context.commit('updateOrderMember', result.data)
    },

    async loadOrderSet(context, query) {
      const result = await agent.Orders.allSet(query)
      context.commit('updateOrderSet', result.data)
    },

    async loadOrderSubs(context, query) {
      const result = await agent.Orders.allSubs(query)
      context.commit('updateOrderSubs', result.data)
    },

    async loadOrderProgress(context, id) {
      const result = await agent.Orders.allProgress(id)
      context.commit('updateOrderProgress', result.data)
    },

    async loadCanceledList(context, query) {
      const result = await agent.Orders.allCanceled(query)
      context.commit('updateCanceledList', result.data)
    },

    async editCanceledStatus(context, query) {
      await agent.Orders.updateCanceledStatus(query)
    },

    async editOrder(context, orders) {
      const obj = {
        ...orders,
      }
      await agent.Orders.update(obj)
    },

    async getShippingFee(context, addr) {
      const result = await agent.Orders.orderShippingFee(addr)
      console.log('data:', result.data)
      context.commit('updateShippingFeeInfo', result.data)
    },
  },
}

export default order
