import agent from '../../api/agent'

const shipping = {
  namespaced: true,
  state: {
    deliveryslipsList: [],
    deliveriesList: [],
    EstimatedList: [],
    deliveriesProductCodes: [],
    deliveriesMaster: [],
    deliverieyStatus: [],
    shippingInfoList: [],
    selectedDeliveries: {
    },
    selectedDeliveryslip: {
    },
    selectedTrackingNo: {
    },
    selectedShippingInfo: [],
  },
  mutations: {
    updateDeliveryslipsList(state, newValue) {
      state.deliveryslipsList = newValue
    },
    clearDeliveryslipsList(state) {
      state.deliveryslipsList = []
    },
    updateSelectedDeliveries(state, newValue) {
      state.selectedDeliveries = newValue
    },
    updateEstimatedList(state, newValue) {
      state.EstimatedList = newValue
    },
    updateDeliveriesList(state, newValue) {
      state.deliveriesList = newValue.map(n => {
        const obj = n
        obj.addr_name = `${n.addr_sei} ${n.addr_mei}`

        return obj
      })
      state.deliveriesMaster = []
      state.deliveriesProductCodes = newValue.map(v => {
        state.deliveriesMaster[v.product_code] = v.product_name

        return v.product_code
      })
    },
    clearDeliveriesList(state) {
      state.deliveriesList = []
    },
    clearDeliveriesProductCodes(state) {
      state.deliveriesProductCodes = []
    },
    updateDeliveryStatusList(state, newValue) {
      state.deliverieyStatus = newValue
    },
    clearDeliveryStatusList(state) {
      state.deliverieyStatus = []
    },
    updateSelectedDeliveryslip(state, newValue) {
      state.selectedDeliveryslip = newValue
    },
    updateSelectedTrackingNo(state, newValue) {
      state.selectedTrackingNo = newValue
    },
    updateShippingInfoList(state, newValue) {
      state.shippingInfoList = newValue
    },
    clearShippingInfoList(state) {
      state.shippingInfoList = []
    },
    updateSelectedShippingInfo(state, newValue) {
      state.selectedShippingInfo = newValue
    },
    clearSelectedDeliveryslip(state) {
      state.selectedDeliveryslip = ''
    },
  },
  actions: {
    async loadDeliveryslipsList(context, query) {
      const result = await agent.Shippings.getDeliveryslips(query)
      context.commit('updateDeliveryslipsList', result.data)
    },
    async loadDeliveriesList(context, query) {
      const result = await agent.Shippings.getDeliveries(query)
      context.commit('updateDeliveriesList', result.data)
    },
    async loadEstimatedList(context, query) {
      const result = await agent.Shippings.estimated(query)
      context.commit('updateEstimatedList', result.data)
    },
    async loadDeliveries(context, id) {
      const result = await agent.Shippings.deliveries(id)
      console.log('result.data', result.data)
      context.commit('updateSelectedDeliveries', result.data)
    },
    async loadDeliveryStatusList(context, query) {
      const result = await agent.Shippings.getDeliverystatus(query)
      context.commit('updateDeliveryStatusList', result.data)
    },
    async loadDeliveryslip(context, id) {
      context.commit('clearSelectedDeliveryslip')
      const result = await agent.Shippings.detail(id)
      console.log('result.data', result.data)
      context.commit('updateSelectedDeliveryslip', result.data)
    },
    async loadTrackingNo(context, id) {
      const result = await agent.Shippings.trackingNo(id)
      context.commit('updateSelectedTrackingNo', result.data)
    },
    async loadShippingInfoList(context, query) {
      context.commit('clearShippingInfoList')
      const result = await agent.Shippings.getShippingInfo(query)
      context.commit('updateShippingInfoList', result.data)
    },
    async loadShippingInfo(context, id) {
      const result = await agent.Shippings.detailShippingInfo(id)
      console.log('result.data', result.data)
      context.commit('updateSelectedShippingInfo', result.data)
    },
    async editTrackingNo(context, TrackingNo) {
      const obj = {
        ...TrackingNo,
      }
      await agent.Shippings.update(obj)
    },
    async exportDeliverySlips(context, query) {
      await agent.Shippings.downloadDeliverySlips(query)
    },
    async editShippingInfo(context, item) {
      const obj = {
        ...item,
      }
      await agent.Shippings.updateShippingInfo(obj)
    },
  },
}

export default shipping
