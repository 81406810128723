import agent from '../../api/agent'

const item = {
  namespaced: true,
  state: {
    itemList: [],
    itemCodes: [],
    itemNames: [],
    selectedItem: {
    },
  },
  mutations: {
    updateItemList(state, newValue) {
      state.itemList = newValue
      newValue.forEach((sl, i) => {
        if (sl.images) {
          state.itemList[i].image = `${sl.imgUrl_prefix}/${sl.images.split('"')[1].replace(/^\//g, '')}`
        } else {
          state.itemList[i].image = ''
        }
      })
    },
    updateItemcodes(state, newValue) {
      state.itemCodes = newValue.map(v => v.item_code)
      state.itemNames = newValue.map(v => v.item_name)
    },
    updateSelectedItem(state, newValue) {
      state.selectedItem = newValue
    },
    clearItemList(state) {
      state.itemList = []
    },
    clearSelectedItem(state) {
      state.selectedItem = {
      }
    },
    clearItemCodes(state) {
      state.itemCodes = []
    },
  },
  actions: {
    async loadItemList(context, query) {
      const result = await agent.Items.all(query)
      context.commit('updateItemList', result.data)
    },
    async createItem(context, items) {
      await agent.Items.add(items)
    },
    async loadItem(context, id) {
      const result = await agent.Items.detail(id)
      context.commit('updateSelectedItem', result.data)
    },
    async loadItemCodes(context) {
      // console.log('context@:', context)
      const result = await agent.Items.allCodes()

      // console.log('result@:', result)
      context.commit('updateItemcodes', result.data)
    },
    async editItem(context, items) {
      const obj = {
        ...items,
      }
      await agent.Items.update(obj)
    },

    async deleteItem(context, id) {
      await agent.Items.delete(id)
    },
  },
}

export default item
