import agent from '../../api/agent'

const monthlyCarryoverStore = {
  namespaced: true,
  state: {
    f_MonthlyCarryovers: [],
    selMonthlyCarryover: {
    },
    MonthlyCarryover: [],
  },

  getters: {
  },

  mutations: {
    updateMonthlyCarryovers(state, newValue) {
      state.MonthlyCarryover = newValue
    },

    updateSelectedMonthlyCarryover(state, newValue) {
      state.selMonthlyCarryover = newValue
    },

    filterMonthlyCarryover(state, newValue) {
      state.f_MonthlyCarryovers = state.MonthlyCarryover.filter(f => {
        const pFlag = newValue ? f.item_code === newValue : true

        return pFlag
      })
    },

    clearMonthlyCarryover(state) {
      state.MonthlyCarryover = []
      state.f_MonthlyCarryovers = []
    },
    clearSelectedMonthlyCarryover(state) {
      state.selMonthlyCarryover = {
      }
    },
  },

  actions: {
    async loadMonthlyCarryovers(context, Month) {
      console.log('monthlCarryoverStore, loadMonthlyCarryovers,month:', Month)
      const result = await agent.MonthlyCarryover.all({
        selMonth: Month,
      })
      context.commit('updateMonthlyCarryovers', result.data)
    },

    async loadMonthlyCarryover(context, id) {
      const result = await agent.MonthlyCarryover.detail(id)
      context.commit('updateSelectedMonthlyCarryover', result.data)
    },

    async editMonthlyCarryover(context, material) {
      await agent.MonthlyCarryover.update(material)
    },
  },
}

export default monthlyCarryoverStore
