import agent from '../../api/agent'

const largeclass = {
  namespaced: true,
  state: {
    largeclassList: [],
    largeclass: undefined,
    selectedLargeClass: undefined,
  },
  mutations: {
    updateLargeClassList(state, value) {
      state.largeclassList = value
    },
    updateSelectedLargeClass(state, value) {
      state.selectedLargeClass = value
    },
  },
  actions: {
    async loadLargeClassList(context) {
      const result = await agent.LargeClasses.all()
      context.commit('updateLargeClassList', result.data)
    },
    async loadLargeClass(context, id) {
      try {
        const result = await agent.LargeClasses.detail(id)
        context.commit('updateSelectedLargeClass', result.data)
      } catch (error) {
        console.log('loadLargeClass,error:', error)
      }
    },
    async createLargeClass(context, largeclasses) {
      await agent.LargeClasses.add(largeclasses)
    },
    async editLargeClass(context, largeclasses) {
      const obj = { ...largeclasses }
      await agent.LargeClasses.update(obj)
    },
  },
}

export default largeclass
