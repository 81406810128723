import agent from '../../api/agent'

const smallclass = {
  namespaced: true,
  state: {
    smallclassList: [],
    smallclass: undefined,
    selectedSmallClass: undefined,
  },
  mutations: {
    updateSmallClassList(state, value) {
      state.smallclassList = value
    },
    updateSelectedSmallClass(state, value) {
      state.selectedSmallClass = value
    },
  },
  actions: {
    async loadSmallClassList(context) {
      const result = await agent.SmallClasses.all()
      context.commit('updateSmallClassList', result.data)
    },
    async loadSmallClass(context, id) {
      try {
        const result = await agent.SmallClasses.detail(id)
        context.commit('updateSelectedSmallClass', result.data)
      } catch (error) {
        console.log('loadSmallClass,error:', error)
      }
    },
    async createSmallClass(context, smallclasses) {
      await agent.SmallClasses.add(smallclasses)
    },
    async editSmallClass(context, smallclasses) {
      const obj = { ...smallclasses }
      await agent.SmallClasses.update(obj)
    },
  },
}

export default smallclass
