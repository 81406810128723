import agent from '../../api/agent'

const scheduled = {
  namespaced: true,
  state: {
    scheduledList: [],
    selectedScheduled: {
    },
    SubsSchOrderProgressList: [],
    SubsStatusList: [],
  },
  mutations: {
    updateSchdeduledList(state, newValue) {
      state.scheduledList = newValue
    },

    updateSelectedScheduled(state, newValue) {
      state.selectedScheduled = newValue
    },

    updateSubsSchOrderProgress(state, newValue) {
      state.SubsSchOrderProgressList = newValue
    },

    updateSubsStatusList(state, newValue) {
      state.SubsStatusList = newValue
    },
    clearScheduledList(state) {
      state.scheduledList = []
    },
    clearSelectedScheduled(state) {
      state.selectedScheduled = {
      }
    },
    clearSubsStatusList(state) {
      state.SubsStatusList = []
    },
  },
  actions: {
    async loadScheduledList(context, query) {
      const result = await agent.ScheduledOrders.all(query)
      context.commit('updateSchdeduledList', result.data)
    },

    async loadScheduled(context, id) {
      const result = await agent.ScheduledOrders.detail(id)
      context.commit('updateSelectedScheduled', result.data)
    },

    async loadSubsSchOrderProgress(context, id) {
      const result = await agent.ScheduledOrders.progress(id)
      context.commit('updateSubsSchOrderProgress', result.data)
    },

    async loadSubsStatusList(context, query) {
      const result = await agent.ScheduledOrders.allSubsStatus(query)
      context.commit('updateSubsStatusList', result.data)
    },

    async editScheduled(context, Scheduled) {
      const obj = {
        ...Scheduled,
      }

      await agent.ScheduledOrders.update(obj)
    },

  },
}

export default scheduled
