import agent from '../../api/agent'

const subscribedmembers = {
  namespaced: true,
  state: {
    subscribedmemberList: [],
  },
  mutations: {
    updateSubscribedmembers(state, newValue) {
      state.subscribedmemberList = newValue
    },
    clearSubscribedmembers(state) {
      state.subscribedmemberList = []
    },
  },
  actions: {
    async loadSubscribedmembers(context, query) {
      const result = await agent.SubscribedMembers.all(query)
      context.commit('updateSubscribedmembers', result.data)
    },
  },
}

export default subscribedmembers
