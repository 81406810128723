import axios from 'axios'
import fileDownload from 'js-file-download'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

// console.log('BaseURL', axios.defaults.baseURL, process.env.VUE_APP_API_ENDPOINT)

// eslint-disable-next-line consistent-return
export function init(store, router) {
  axios.interceptors.request.use(
    config => {
      // Do something before request is sent
      const accessToken = localStorage.getItem('accessToken')

      // eslint-disable-next-line no-param-reassign
      if (accessToken) config.headers.Authorization = `${accessToken}`

      return config
    },
    error => Promise.reject(error),
  )
  axios.interceptors.response.use(
    async response => {
      const accessToken = response.headers.authorization

      if (accessToken !== undefined) {
        localStorage.setItem('accessToken', accessToken)
      }

      return response
    },
    error => {
      const { status, config } = error.response

      const accessToken = localStorage.getItem('accessToken')

      switch (status) {
        case 422:
          break
        case 401:
          if (config.headers.Authorization && accessToken !== config.headers.Authorization) {
            config.headers.Authorization = accessToken

            return axios(config)
          }

          router.push({
            name: 'auth-login',
          })
          break
        default:
          break
      }

      return Promise.reject(error)
    },
  )

  // console.log(router)
  // console.log(store.method)
  if (store.method !== undefined) {
    return axios(store)
  }
}
const responseBody = response => response.data

const Suppliers = {
  all: query => axios.get('/suppliers', {
    params: query,
  }).then(responseBody),
  add: supplier => axios.post('/suppliers', supplier),
  update: supplier => axios.put(`/suppliers/${supplier.id}`, supplier),
  detail: id => axios.get(`/suppliers/${id}`).then(responseBody),
  delete: id => axios.delete(`/suppliers/${id}`),
}

const LargeClasses = {
  all: query => axios.get('/largeclasses', {
    params: query,
  }).then(responseBody),
  add: largeclass => axios.post('/largeclasses', largeclass),
  update: largeclass => axios.put(`/largeclasses/${largeclass.id}`, largeclass),
  detail: id => axios.get(`/largeclasses/${id}`).then(responseBody),
}

const MediumClasses = {
  all: query => axios.get('/mediumclasses', {
    params: query,
  }).then(responseBody),
  add: mediumclass => axios.post('/mediumclasses', mediumclass),
  update: mediumclass => axios.put(`/mediumclasses/${mediumclass.id}`, mediumclass),
  detail: id => axios.get(`/mediumclasses/${id}`).then(responseBody),
}

const SmallClasses = {
  all: query => axios.get('/smallclasses', {
    params: query,
  }).then(responseBody),
  add: smallclass => axios.post('/smallclasses', smallclass),
  update: smallclass => axios.put(`/smallclasses/${smallclass.id}`, smallclass),
  detail: id => axios.get(`/smallclasses/${id}`).then(responseBody),
}

const Items = {
  all: query => axios.get('/items', {
    params: query,
  }).then(responseBody),
  allCodes: query => axios.get('/itemcodes', {
    params: query,
  }).then(responseBody),
  add: item => axios.post('/items', item),
  update: item => axios.put(`/items/${item.id}`, item),
  detail: id => axios.get(`/items/${id}`).then(responseBody),
  delete: id => axios.delete(`/items/${id}`),
}

const Sets = {
  all: query => axios.get('/sets', {
    params: query,
  }).then(responseBody),
  add: set => axios.post('/sets', set),
  update: set => axios.put(`/sets/${set.set_id}`, set),
  detail: id => axios.get(`/sets/${id}`).then(responseBody),
  delete: id => axios.delete(`/sets/${id}`),
  allcodes: query => axios.get('/setcodes', {
    params: query,
  }).then(responseBody),
  items: id => axios.get(`/setitems/${id}`).then(responseBody),
}

const Subscriptions = {
  all: query => axios.get('/subscriptions', {
    params: query,
  }).then(responseBody),
  allcodes: query => axios.get('/subscodes', {
    params: query,
  }).then(responseBody),
  add: subs => axios.post('/subscriptions', subs),
  update: subs => axios.put(`/subscriptions/${subs.subs_id}`, subs),
  detail: id => axios.get(`/subscriptions/${id}`).then(responseBody),
  delete: id => axios.delete(`/subscriptions/${id}`),
  items: id => axios.get(`/subscriptionitems/${id}`).then(responseBody),
  allCourses: query => axios.get('/subscriptioncourses', {
    params: query,
  }).then(responseBody),
  addCourses: courses => axios.post('/subscriptioncourses', courses),
  updateCourses: courses => axios.put(`/subscriptioncourses/${courses.course_id}`, courses),
  detailCourses: id => axios.get(`/subscriptioncourses/${id}`).then(responseBody),
}

const PurchasedItems = {
  all: query => axios.get('/purchaseditems', {
    params: query,
  }).then(responseBody),
  add: purchased => axios.post('/purchaseditems', purchased),
  update: purchased => axios.put(`/purchaseditems/${purchased.id}`, purchased),
  detail: id => axios.get(`/purchaseditems/${id}`).then(responseBody),
  delete: id => axios.delete(`/purchaseditems/${id}`),
}

const OwnCodes = {
  Generate: query => axios.get('/owncode', {
    params: query,
  }).then(responseBody),
}

const Nfcontents = {
  all: query => axios.get('/nfcontent', {
    params: query,
  }).then(responseBody),
}

const Members = {
  all: query => axios.get('/members', {
    params: query,
  }).then(responseBody),
  add: member => axios.post('/members', member),
  update: member => axios.put(`/members/${member.mbr_id}`, member),
  detail: id => axios.get(`/members/${id}`).then(responseBody),
  allAddresses: query => axios.get('/addresses', {
    params: query,
  }).then(responseBody),

  addresses: id => axios.get(`/addresses?mbr_id=${id}`).then(responseBody),
  allStatus: query => axios.get('/memberstatus', {
    params: query,
  }).then(responseBody),
  detailStatus: id => axios.get(`/memberstatus/${id}`).then(responseBody),
}

const Orders = {
  all: query => axios.get('/orders', {
    params: query,
  }).then(responseBody),
  add: order => axios.post('/orders', order),
  update: order => axios.put(`/orders/${order.ord_id}`, order),
  detail: id => axios.get(`/orders/${id}`).then(responseBody),
  allStatus: query => axios.get('/orderstatus', {
    params: query,
  }).then(responseBody),
  detailStatus: id => axios.get(`/orderstatus/${id}`).then(responseBody),
  allMember: query => axios.get('/ordersbymember', {
    params: query,
  }).then(responseBody),
  allSet: query => axios.get('/orderedsets', {
    params: query,
  }).then(responseBody),
  allSubs: query => axios.get('/orderedsubscriptions', {
    params: query,
  }).then(responseBody),
  allProgress: id => axios.get(`/orderprogresses/${id}`).then(responseBody),
  allCanceled: query => axios.get('/canceledorders', {
    params: query,
  }).then(responseBody),
  updateCanceledStatus: order => axios.put(`/canceledorders/${order.ord_id}`, order),

  orderShippingFee: addr => axios.post('/ordershippingfee', addr).then(responseBody),
  exportOrdersDetail: query => axios.get('/exportordersdetail', {
    params: query,
  }).then(responseBody),
}

const Products = {
  allProducts: query => axios.get('/getproducts', {
    params: query,
  }).then(responseBody),
}

const Shippings = {
  getDeliveryslips: query => axios.get('/deliveryslips', {
    params: query,
  }).then(responseBody),
  getDeliveries: query => axios.get('/deliveries', {
    params: query,
  }).then(responseBody),
  estimated: query => axios.get('/shipping-estimated', {
    params: query,
  }).then(responseBody),
  getDeliverystatus: query => axios.get('/deliverystatus', {
    params: query,
  }).then(responseBody),
  deliveries: id => axios.get(`/deliveries/${id}`).then(responseBody),
  detail: id => axios.get(`/deliveryslips/${id}`).then(responseBody),
  update: shipping => axios.put(`/sliptrackingno/${shipping.delv_id}`, shipping),
  trackingNo: id => axios.get(`/sliptrackingno/${id}`).then(responseBody),
  downloadDeliverySlips: query => axios.get('/exportPDF', {
    responseType: 'blob',
    params: query,
  }).then(res => {
    console.log('pdf', res)

    const content = res.headers['content-disposition']
    let filename = 'report.pdf'
    if (content) {
      let name1 = content.match(/filename=(.*);/)[1]
      let name2 = content.match(/filename\*=(.*)/)[1]
      name1 = decodeURIComponent(name1.replaceAll('"', ''))
      name2 = decodeURIComponent(name2.substring(7))
      filename = name2 || name1
    }
    console.log('filename', filename)

    fileDownload(res.data, filename)
  }),
  getShippingInfo: query => axios.get('/shippinginfo', {
    params: query,
  }).then(responseBody),
  detailShippingInfo: id => axios.get(`/shippinginfo/${id}`).then(responseBody),
  updateShippingInfo: shipping => axios.put(`/shippinginfo/${shipping.sf_id}`, shipping),
}

const Categories = {
  tree: query => axios.get('/categorytree', {
    params: query,
  }).then(responseBody),
  plain: query => axios.get('/categories', {
    params: query,
  }).then(responseBody),
}

const Media = {
  getList: query => axios.get('/media/list', {
    params: query,
  }).then(responseBody),
  upload: query => {
    const formData = new FormData()
    formData.append('img', query.img)
    formData.append('tgtDirectory', query.tgtDirectory)
    formData.append('tgtFolderId', query.tgtFolderId)

    return axios
      .post('/mediafiles', formData, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
  },
  delete: query => axios.delete('/mediafiles', {
    params: query,
  }),
  rename: mediafiles => axios.put('/mediafiles', mediafiles),
  createFolder: folder => axios.post('/mediafolders', folder),
}

// auth & user
const Auth = {
  login: user => axios.post('/login', user),
  logout: () => axios.post('/logout'),
}

// 前月繰越
const MonthlyCarryover = {
  all: query => axios.get('/monthlycarryover', {
    params: query,
  }).then(responseBody),
  detail: id => axios.get(`/monthlycarryover/${id}`).then(responseBody),
  update: carryover => axios.put(`/monthlycarryover/${carryover.id}`, carryover),
}

// 現在在庫
const CurrentlyStock = {
  all: query => axios.get('/currentlystock', {
    params: query,
  }).then(responseBody),
}

// 月次在庫
const MonthlyStock = {
  all: query => axios.get('/monthlystock', {
    params: query,
  }).then(responseBody),
}

// 月次入出庫詳細
const DailyStock = {
  dailyitem: query => axios.get('/dailyitemstock', {
    params: query,
  }).then(responseBody),
  all: query => axios.get('/dailystock', {
    params: query,
  }).then(responseBody),
}

const Faqs = {
  all: query => axios.get('/faqs', {
    params: query,
  }).then(responseBody),
  add: faq => axios.post('/faqs', faq),
  update: faq => axios.put(`/faqs/${faq.id}`, faq),
  detail: id => axios.get(`/faqs/${id}`).then(responseBody),
  delete: id => axios.delete(`/faqs/${id}`),
}

const User = {
  all: query => axios.get('/users', {
    params: query,
  }).then(responseBody),
  createInfo: () => axios.get('/users/create').then(responseBody),
  add: user => axios.post('/users', user),
  update: user => axios.put(`/users/${user.id}`, user),
  updateInfo: id => axios.get(`/users/${id}/edit`).then(responseBody),
  delete: id => axios.delete(`/users/${id}`),
  detail: id => axios.get(`/users/${id}/edit`).then(responseBody),
}

const Role = {
  all: query => axios.get('/roles', {
    params: query,
  }).then(responseBody),
  createInfo: () => axios.get('/roles/create').then(responseBody),
  add: role => axios.post('/roles', role),
  update: role => axios.put(`/roles/${role.id}`, role),
  updateInfo: id => axios.get(`/roles/${id}/edit`).then(responseBody),
  delete: id => axios.delete(`/roles/${id}`),
  detail: id => axios.get(`/roles/${id}/edit`).then(responseBody),
}

const Settlement = {
  all: query => axios.get('/settlements', {
    params: query,
  }).then(responseBody),
  detail: id => axios.get(`/settlements/${id}`).then(responseBody),
}

const PaymentHistory = {
  all: query => axios.get('/paymenthistory', {
    params: query,
  }).then(responseBody),
}

const ScheduledOrders = {
  all: query => axios.get('/scheduledorders', {
    params: query,
  }).then(responseBody),
  detail: id => axios.get(`/scheduledorders/${id}`).then(responseBody),
  progress: id => axios.get(`/subsschorderprogresses/${id}`).then(responseBody),
  allSubsStatus: query => axios.get('/subscriptionschedulestatus', {
    params: query,
  }).then(responseBody),
  update: scheduled => axios.put(`/scheduledorders/${scheduled.sch_id}`, scheduled),
}

const SubscribedMembers = {
  all: query => axios.get('/subscribedmembers', {
    params: query,
  }).then(responseBody),
}

const Prefecture = {
  all: query => axios.get('/prefectures', {
    params: query,
  }).then(responseBody),
  area: query => axios.get('/prefecturegroupids', {
    params: query,
  }).then(responseBody),
}

const Coupons = {
  all: query => axios.get('/coupons', {
    params: query,
  }).then(responseBody),
  add: coupon => axios.post('/coupons', coupon),
  update: coupon => axios.put(`/coupons/${coupon.id}`, coupon),
  detail: id => axios.get(`/coupons/${id}`).then(responseBody),
  delete: id => axios.delete(`/coupons/${id}`),
  getInfo: query => axios.get('/couponinfo', {
    params: query,
  }).then(responseBody),
}

const Dashboard = {
  all: query => axios.get('/dashboard-list?', {
    params: query,
  }).then(responseBody),
}

const agent = {
  Suppliers,
  LargeClasses,
  MediumClasses,
  SmallClasses,
  Items,
  Sets,
  Categories,
  Members,
  Orders,
  Products,
  Shippings,
  Media,
  Subscriptions,
  OwnCodes,
  Nfcontents,
  Auth,
  PurchasedItems,
  MonthlyCarryover,
  MonthlyStock,
  CurrentlyStock,
  DailyStock,
  Faqs,
  User,
  Role,
  Settlement,
  Prefecture,
  ScheduledOrders,
  PaymentHistory,
  SubscribedMembers,
  Coupons,
  Dashboard,
}

export default agent
