<template>
  <v-app>
    <vertical-nav-menu
      :is-drawer-open.sync="isDrawerOpen"
    ></vertical-nav-menu>
    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <v-app-bar-nav-icon
        class="d-blockme-2 ml-1"
        @click="isDrawerOpen = !isDrawerOpen"
      ></v-app-bar-nav-icon>
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <v-spacer></v-spacer>

          <theme-switcher></theme-switcher>

          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pt-2 pl-2 pr-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <!-- <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2021 <a
              href="https://themeselection.com"
              class="text-decoration-none"
              target="_blank"
            >ThemeSelection</a></span>
          <span class="d-sm-inline d-none">
            <a
              href="https://themeselection.com/products/category/download-free-admin-templates/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Freebies</a>
            <a
              href="https://themeselection.com/blog/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Blog</a>
            <a
              href="https://github.com/themeselection/materio-vuetify-vuejs-admin-template-free/blob/main/LICENSE"
              target="_blank"
              class="text--secondary text-decoration-none"
            >MIT Licence</a>
          </span>
        </div> -->
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'
import {
  mdiMagnify, mdiBellOutline, mdiGithub,
} from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'

// import BreadCrumb from './components/BreadCrumb.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,

    // BreadCrumb,
  },

  // data: () => ({
  //   items: [
  //     {
  //       text: '商品管理',
  //       disabled: false,
  //       href: 'crushingactual-list',
  //     },
  //     {
  //       text: '単品',
  //       disabled: false,
  //       href: 'crushingactual-list',
  //     },
  //     {
  //       text: 'Link 2',
  //       disabled: true,
  //       href: 'crushingactual-list',
  //     },
  //   ],
  // }),
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
</style>
