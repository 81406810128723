import agent from '../../api/agent'

const subs = {
  namespaced: true,
  state: {
    subsList: [],
    selectedSubs: {
    },
    subsCodes: [],
    subsYears: [],
    subscriptionItems: [],
    courseNames: [],
    courseMaster: {
    },
    subscriptionCoursesList: [],

    selectedCourses: {
    },
  },
  mutations: {
    updateSubsList(state, newValue) {
      state.subsList = newValue
      state.subsYears = newValue.map(v => v.subs_year)
      state.subsYears.sort((a, b) => a - b)
      newValue.forEach((sl, i) => {
        if (sl.subs_images) {
          state.subsList[i].subs_image = `${sl.imgUrl_prefix}/${sl.subs_images.split('"')[1].replace(/^\//g, '')}`
        } else {
          state.subsList[i].subs_image = ''
        }

        state.subsList[i].subsSeason = `${sl.subs_year}-S${sl.subs_season}`
      })
    },
    updateSelectedSubs(state, newValue) {
      state.selectedSubs = newValue
    },

    updateSubsCodes(state, newValue) {
      state.subsCodes = newValue.map(v => v.subs_code)
    },

    updateSubscriptionItems(state, newValue) {
      state.subscriptionItems = newValue
    },

    updateSubscriptionCourses(state, newValue) {
      state.subscriptionCoursesList = newValue
      state.courseMaster = {
      }
      state.courseNames = newValue.map(v => {
        state.courseMaster[v.course_name] = {
          code: v.course_code, id: v.course_id,
        }

        return v.course_name
      })
    },
    clearSubscriptionCoursesList(state) {
      state.subscriptionCoursesList = []
    },
    clearSubsList(state) {
      state.subsList = []
    },
    clearSelectedSubs(state) {
      state.selectedSubs = {
      }
    },
    clearSubsCodes(state) {
      state.subsCodes = []
    },
    updateSelectedCourses(state, newValue) {
      state.selectedCourses = newValue
    },
  },
  actions: {
    async loadSubsList(context, query) {
      const result = await agent.Subscriptions.all(query)
      context.commit('updateSubsList', result.data)

      // console.log('loadSubsList', result.data)
    },
    async createSubs(context, subscription) {
      await agent.Subscriptions.add(subscription)
    },
    async loadSubs(context, id) {
      // console.log('id', id)
      const result = await agent.Subscriptions.detail(id)
      context.commit('updateSelectedSubs', result.data)
    },
    async editSubs(context, Subscription) {
      const obj = {
        ...Subscription,
      }
      await agent.Subscriptions.update(obj)
    },

    async deleteSubs(context, id) {
      await agent.Subscriptions.delete(id)
    },

    async loadSubscriptionCodes(context) {
      const result = await agent.Subscriptions.allcodes()
      context.commit('updateSubsCodes', result.data)
    },

    async loadSubscriptionItems(context, query) {
      const result = await agent.Subscriptions.items(query)
      context.commit('updateSubscriptionItems', result.data)
    },

    async loadSubscriptionCourses(context, query) {
      context.commit('clearSubscriptionCoursesList')
      const result = await agent.Subscriptions.allCourses(query)
      context.commit('updateSubscriptionCourses', result.data)
    },

    async createCourses(context, courses) {
      await agent.Subscriptions.addCourses(courses)
    },

    async loadCourses(context, id) {
      const result = await agent.Subscriptions.detailCourses(id)
      context.commit('updateSelectedCourses', result.data)
    },

    async editCourses(context, courses) {
      await agent.Subscriptions.updateCourses(courses)
    },

  },
}

export default subs
