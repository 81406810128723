import agent from '../../api/agent'

const purchased = {
  namespaced: true,
  state: {
    purchasedList: [],
    selectedPurchased: {
    },
  },
  mutations: {
    updatePurchasedList(state, newValue) {
      state.purchasedList = newValue
    },
    updateSelectedPurchased(state, newValue) {
      state.selectedPurchased = newValue
    },
    clearPurchasedList(state) {
      state.purchasedList = []
    },
    clearSelectedPurchased(state) {
      state.selectedPurchased = {
      }
    },
  },
  actions: {
    async loadPurchasedList(context, query) {
      const result = await agent.PurchasedItems.all(query)
      context.commit('updatePurchasedList', result.data)
    },
    async createPurchased(context, purchaseds) {
      await agent.PurchasedItems.add(purchaseds)
    },
    async loadPurchased(context, id) {
      const result = await agent.PurchasedItems.detail(id)
      context.commit('updateSelectedPurchased', result.data)
    },
    async editPurchased(context, Purchaseds) {
      const obj = {
        ...Purchaseds,
      }
      await agent.PurchasedItems.update(obj)
    },

    // eslint-disable-next-line no-unused-vars
    async deletePurchased(context, id) {
      await agent.PurchasedItems.delete(id)
    },
  },
}

export default purchased
