import agent from '../../api/agent'

const dailyStockStore = {
  namespaced: true,
  state: {
    ItemDailyStock: [],
    SetDailyStock: [],
    SubsDailyStock: [],
    item_name: '',
    qtyin_lmSum: 0,
    qtyout_lmSum: 0,
    net_qty: 0,
    monco_id: 0,
  },

  getters: {
  },

  mutations: {
    updateItemDailyStock(state, newValue) {
      let sumQty = newValue.net_qty
      state.ItemDailyStock = newValue.dly_itemStock.map(f => {
        const obj = f
        obj.daily_qty = f.quantity_in - f.quantity_out
        obj.net_qty = sumQty + f.quantity_in - f.quantity_out
        sumQty = obj.net_qty

        return obj
      })
      state.qtyin_lmSum = newValue.qtyin_lmSum
      state.qtyout_lmSum = newValue.qtyout_lmSum
      state.net_qty = newValue.net_qty
      state.monco_id = newValue.monco_id
      state.item_name = newValue.item_name
    },
    updateSetDailyStock(state, newValue) {
      state.SetDailyStock = newValue
    },
    updateSubsDailyStock(state, newValue) {
      state.SubsDailyStock = newValue
    },
    clearItemDailyStock(state) {
      state.ItemDailyStock = []
      state.item_name = ''
      state.qtyin_lmSum = 0
      state.qtyout_lmSum = 0
      state.net_qty = 0
      state.monco_id = 0
    },
    clearSetDailyStock(state) {
      state.SetDailyStock = []
    },
    clearSubsDailyStock(state) {
      state.SubsDailyStock = []
    },
  },

  actions: {
    async loadItemDailyStock(context, params) {
      context.commit('clearItemDailyStock')
      const result = await agent.DailyStock.dailyitem(params)

      context.commit('updateItemDailyStock', result.data)
    },

    async loadSetDailytStock(context, params) {
      context.commit('clearSetDailyStock')
      const result = await agent.DailyStock.all(params)

      context.commit('updateSetDailyStock', result.data)
      console.log('updateSetDailyStock', result.data)
    },

    async loadSubsDailytStock(context, params) {
      context.commit('clearSubsDailyStock')
      const result = await agent.DailyStock.all(params)

      context.commit('updateSubsDailyStock', result.data)
    },
  },
}

export default dailyStockStore
