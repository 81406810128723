import agent from '../../api/agent'

const categoryStore = {
  namespaced: true,
  state: {
    categoryTree: null,
  },
  mutations: {
    updateCategoryTree(state, newValue) {
      state.categoryTree = newValue
    },
  },
  actions: {
    async loadCategoryTree({ commit, state }) {
      if (state.categoryTree != null) {
        console.log('categoryTree already loaded')

        return
      }
      const result = await agent.Categories.tree()
      commit('updateCategoryTree', result.data)
    },
  },
}

export default categoryStore
