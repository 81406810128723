import agent from '../../api/agent'

const member = {
  namespaced: true,
  state: {
    memberList: [],
    allAddressesList: [],
    selectedMember: {
    },
    selectedStatus: {
    },
    selectedAddresses: [],
    MemberStatusList: [],
    MemberStatusMaster: [],
  },
  mutations: {
    updateMemberList(state, newValue) {
      state.memberList = newValue
    },
    updateAllAddressesList(state, newValue) {
      state.allAddressesList = newValue
    },
    updateSelectedMember(state, newValue) {
      state.selectedMember = newValue
    },
    updateSelectedAddresses(state, newValue) {
      state.selectedAddresses = newValue
    },
    updateMemberStatusList(state, newValue) {
      state.MemberStatusList = newValue
      state.MemberStatusMaster = newValue.map(n => {
        const obj = {
        }
        obj.text = `${n.ms_code}: ${n.ms_desc}`
        obj.value = n.ms_code

        return obj
      })
    },
    clearMemberStatusList(state) {
      state.MemberStatusList = []
    },

    updateSelectedStatus(state, newValue) {
      console.log('status', newValue)
      state.selectedStatus = newValue
    },
  },
  actions: {
    async loadMemberList(context, query) {
      const result = await agent.Members.all(query)
      context.commit('updateMemberList', result.data)
    },
    async loadAllAddressesList(context, query) {
      const result = await agent.Members.allAddresses(query)
      context.commit('updateAllAddressesList', result.data)
    },
    async loadMemberStatusList(context, query) {
      context.commit('clearMemberStatusList')
      const result = await agent.Members.allStatus(query)
      context.commit('updateMemberStatusList', result.data)
    },
    async createMember(context, members) {
      await agent.Members.add(members)
    },
    async loadMember(context, id) {
      const result = await agent.Members.detail(id)
      context.commit('updateSelectedMember', result.data)
    },

    async loadMemberStatus(context, id) {
      const result = await agent.Members.detailStatus(id)
      context.commit('updateSelectedStatus', result.data)
    },
    async loadAddresses(context, id) {
      const result = await agent.Members.addresses(id)
      context.commit('updateSelectedAddresses', result.data)
    },
    async editMember(context, members) {
      const obj = {
        ...members,
      }
      console.log('obj:', obj)
      await agent.Members.update(obj)
    },
  },
}

export default member
