import agent from '../../api/agent'

const owncode = {
  namespaced: true,
  state: {
    owncodeList: [],
  },
  mutations: {
    updateOwncodeList(state, newValue) {
      state.owncodeList = newValue
    },
  },
  actions: {
    async loadOwncodes(context) {
      console.log('context@:', context)
      const result = await agent.OwnCodes.Generate()
      console.log('result@:', result)
      context.commit('updateOwncodeList', result.data)
    },
  },
}

export default owncode
