import agent from '../../api/agent'

const mediaStore = {
  namespaced: true,
  state: {
    mediaList: [],
    targetFolderId: 0,
    mediaBreadcrumbs: [
      {
        text: 'All media',
        folderId: 0,
      },
    ],
  },

  mutations: {
    updateMediaList(state, newValue) {
      state.mediaList = newValue
    },

    updateMediaBreadcrumbs(state, tgtfolder) {
      const Breadcrumb = {
        text: tgtfolder.name,
        folderId: tgtfolder.id,
      }
      state.mediaBreadcrumbs.push(Breadcrumb)
      console.log('updateMediaBreadcrumbs', state.mediaBreadcrumbs)
    },

    reLoadMediaBreadcrumbs(state, tgtfolder) {
      const currentIndex = state.mediaBreadcrumbs.findIndex(m => m.folderId === tgtfolder.folderId) + 1
      const deleteCount = state.mediaBreadcrumbs.length - currentIndex

      state.mediaBreadcrumbs.splice(currentIndex, deleteCount)
      console.log('reLoadMediaBreadcrumbs', currentIndex, deleteCount, state.mediaBreadcrumbs)
    },
    updateTargetFolderId(state, newValue) {
      state.targetFolderId = newValue.folder_id
    },
  },

  actions: {
    async loadMediaList(context, query) {
      try {
        console.log('loadMediaList', query, query.folder_id)
        context.commit('updateTargetFolderId', query)
        const result = await agent.Media.getList(query)
        context.commit('updateMediaList', result.data)
      } catch (error) {
        console.log(error)
      }
    },

    async uploadImg(context, img) {
      await agent.Media.upload(img)
    },

    async deleteMedia(context, query) {
      await agent.Media.delete(query)
    },

    async renameMedia(context, query) {
      await agent.Media.rename(query)
    },

    async deleteFileOrFolder(context, query) {
      await agent.Media.deleteFile(query)
    },

    async createFolder(context, query) {
      await agent.Media.createFolder(query)
    },
  },
}

export default mediaStore
