import agent from '../../api/agent'

const nfcontent = {
  namespaced: true,
  state: {
    nfcontentList: [],
  },
  mutations: {
    updateNfcontentList(state, newValue) {
      state.nfcontentList = newValue
    },
  },
  actions: {
    async loadNfcontentList(context) {
      // console.log('context@:', context)
      const result = await agent.Nfcontents.all()

      // console.log('result@:', result)
      context.commit('updateNfcontentList', result.data)
    },
  },
}

export default nfcontent
