import agent from '../../api/agent'

const dashboard = {
  namespaced: true,
  state: {
    dashboardList: [],
  },
  mutations: {
    updateDashboardList(state, newValue) {
      state.dashboardList = newValue
    },
    clearDashboardList(state) {
      state.dashboardList = []
    },
  },
  actions: {
    async loadDashboardList(context) {
      const result = await agent.Dashboard.all()
      context.commit('updateDashboardList', result.data)
    },
  },
}

export default dashboard
