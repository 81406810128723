import agent from '../../api/agent'

// 月次在庫
const monthlyStockStore = {
  namespaced: true,
  state: {
    ItemMonthlyStock: [],
    f_ItemMonthlyStock: [],
    SetMonthlyStock: [],
    SubsMonthlyStock: [],
  },

  getters: {
  },

  mutations: {
    updateItemMonthlyStock(state, newValue) {
      state.ItemMonthlyStock = newValue
    },

    filterItemMonthlyStock(state, newValue) {
      state.f_ItemMonthlyStock = state.ItemMonthlyStock.filter(f => {
        const iFlag = newValue ? f.item_code === newValue : true

        return iFlag
      })
    },

    updateSetMonthlyStock(state, newValue) {
      state.SetMonthlyStock = newValue
    },

    updateSubsMonthlyStock(state, newValue) {
      state.SubsMonthlyStock = newValue
    },

    clearItemMonthlyStock(state) {
      state.ItemMonthlyStock = []
      state.SetMonthlyStock = []
      state.SubsMonthlyStock = []
      state.f_ItemMonthlyStock = []
    },
  },

  actions: {
    async loadItemMonthlyStock(context, Month) {
      try {
        const result = await agent.MonthlyStock.all({
          type: 'item', month: Month,
        })

        context.commit('updateItemMonthlyStock', result.data)
      } catch (error) {
        console.log(error)
      }
    },

    async loadSetMonthlyStock(context, params) {
      try {
        const result = await agent.MonthlyStock.all(params)

        context.commit('updateSetMonthlyStock', result.data)
      } catch (error) {
        console.log(error)
      }
    },

    async loadSubsMonthlyStock(context, params) {
      try {
        const result = await agent.MonthlyStock.all(params)

        context.commit('updateSubsMonthlyStock', result.data)
      } catch (error) {
        console.log(error)
      }
    },

  },
}

export default monthlyStockStore
